export default {
  data () {
    return {
      timeOut: false
    }
  },
  mounted() {
    this.validationTime()

    window.setInterval(() => {
      this.validationTime()
    }, 60000)

  },
  methods: {
    validationTime() {
      var date = new Date()

      console.log((date.getUTCHours()),(date.getUTCMonth()),(date.getUTCDate()))
      // 7 UTC => 3est 0-23, months 0-11,  date 1-31
      if((date.getUTCHours() >= 7) && (date.getUTCMonth() >= 3) && (date.getUTCDate() >= 1)){
        this.timeOut = false
      } else {
        this.timeOut = true
      }
    }
  }
}
