<template>
  <div class="container-fluid" id="footer-hide">
    <div class="container-footer container">
      <div class="row border-bottom mb-3 pb-3">
        <div class="col-12 col-md-3  text-md-left text-center">
          <a href="https://univision.com"><img src="https://www.univision.com/_next/static/images/logo-univision-color.49f6b4c4450cea582fa78cbc00dee7d8.svg" /></a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          
        </div>
        <div class="col-12 col-md-6 links text-md-right text-center">
          <h3>Acerca de Univision</h3>
          <a href="https://www.univision.com/global/politica-de-privacidad" target="_blank">Política de Privacidad</a>
          
          <a href="https://www.univision.com/aviso-del-derecho-de-excluir-la-venta-de-informacion-personal" target="_blank">No venda Mi Informacion Personal </a>
          
          <a href="https://www.univision.com/terminos-de-uso" target="_blank">Terminos de Uso</a>
          
          <a href="https://corporate.televisaunivision.com/?_gl=1%2A1pg4olk%2A_ga%2ANjQzOTE4MjQyLjE2NDUyMDM2Mzc.%2A_ga_36LKB3355L%2AMTY0NTIwMzYzNy4xLjEuMTY0NTIwNTE2OC40MA.." target="_blank">Informacion de la Empresa</a>
          
          <a href="https://preferences-mgr.truste.com/?&affiliateId=168&locale=es" target="_blank">Elecciones de Publicidad</a>
          
          <a href="https://www.univision.com/archivo" target="_blank">Archivo</a>
          
          <a href="https://www.univision.com/productos-servicios-y-patentes-de-univision-1" target="_blank">Productos,Servicios y Patentes de Univision</a>
          
          <a href="http://www.tvguidelines.org/index_sp.html" target="_blank">Guia Parentales de TV</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

  .container-footer {
    padding: 55px 0;
    min-height: 320px;

    h3 {
      font-size: 21px;
    }

    .links {
      a {
        display: inline-block;
        font-family: "Roboto";
        font-weight: 300;
        font-size: 14px;
        color: #000;
        margin: 5px 10px;
      }
    }
  }


</style>

<script>

  export default {
    name: "Footer"
  }

</script>