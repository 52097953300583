  <template>
  <div id="uvn-logo" class="navbar-layout" ref="navbar-layout">

    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand>
        <router-link :to="{name: 'list'}" custom v-slot="{ navigate, href }">
          <a @click="countLink(`topnav-univisionarios_logo`, 'list')" :href="href" class="navbar-brand uvn-logo"></a>
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav v-model="activeMenu">
        <b-navbar-nav class="ml-auto">
          <b-nav-text>
            <router-link :to="{name: 'acerca'}" custom v-slot="{ navigate, href }">
              <a class="link-nav-univ" id="acerca-nav"  :href="href" @click="countLink(`topnav-acerca_de`, 'acerca')">Acerca de</a>
            </router-link>
          </b-nav-text>          
          <b-nav-text>
            <router-link :to="{name: 'homenajeados'}" custom v-slot="{ navigate, href }">
              <a class="link-nav-univ"  id="homenajeados-nav" :href="href" @click="countLink(`topnav-homenajeados`, 'homenajeados')">Homenajeados</a>
            </router-link>
          </b-nav-text>
          <b-nav-text>
            <router-link :to="{name: 'jurado'}" custom v-slot="{ href }">
              <a class="link-nav-univ" :id="href" href="https://www.univision.com/especiales/noticias/2022/univisionarios/#/" @click="countLink(`topnav-comite`, 'jurado')">Primera Edición</a>
            </router-link>
          </b-nav-text>
          <!-- <b-nav-text> 
            
              <a class="link-nav-univ" target="_blank" id="jurado-nav" href="https://vota.univisionarios.com/" >Votar</a>
            
          </b-nav-text> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<style lang="scss">
  .navbar-layout {
    font-family: "Roboto" !important;
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 170px;
    z-index: 999;
    background-image: url('../assets/bg-nav.png');
    background-repeat: repeat-x;
    background-position: top;
    transition: min-height .25s ease-in-out;

    @media (max-width: 600px) {
      padding: 0 0 15px 0;
      display: block;
      background-position: bottom;
      background-size: cover;

      .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        height: 80vh;
        margin-top: 10vh;
      }
    }

    .navbar {
      max-width: 1320px;
      margin: 25px auto 45px;
      padding: 0 15px;
      transition: margin 0.25s ease-in-out;
    }

    .navbar-dark .navbar-toggler {
      background: #0000007c;
    }

    .menu-icon {
      position: absolute;
      display: block;
      top: -9px;
      right: 15px;
      background: #ffffff1f;
    }

    &.small {
      min-height: 55px;
      padding: 0 0 0 0;

      .navbar {
        margin: 15px auto;
      }
    }

    .sponsor {
      display: block;
      font-family: "Roboto";
      font-weight: 900;
      color: #fff;
      padding: 5px 15px;

      img {
        width: 40px;
        margin: 0 15px;
      }
    }

    .container-menu {
      position: relative;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
    }
    
    .uvn-logo {
      display: block;
      width: 300px;
      height: 85px;
      margin: 0;
      background-size: 100%;
      background-image: url('../assets/logo_univisionarios3.png');
      background-repeat: no-repeat;

      @media (max-width: 600px) {
        width: 220px;
        height: 60px;
      }

      @media (max-width: 320px) {
        width: 180px;
        height: 40px;
      }
    }

    li {
        display: inline-block;
        font-family: "Roboto";
        font-size: 14px;
        margin: 0;

        @media (max-width: 600px) {
          display: inline-block;
          width: 100%;
        }

        a {
          text-align: left;
          display: block;
          padding: 5px 15px;
          font-family: "AkiraExpandedSuperBold";
          font-size: 14px;
          color: #fff !important;

          &.active {
            border-bottom: solid 2px var(--app-yellow);
          }

          @media (max-width: 600px) {
            width: 100%;
            padding: 15px 0;
            font-size: 18px;
            text-align: center;
            text-shadow: 1px 1px 1px #000;
          }
        }
      }

    .sub-menu {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      border-bottom: solid 5px #ae9269;
      list-style: none;

      @media (max-width: 600px) {
        display: none;
        position: relative;
        padding: 35px 0 15px 0;

        &.active {
          display: block;
          padding: 35px 0 15px 0;
        }
      }
    }
  }
</style>

<script>
  import * as d3 from 'd3'
  import timeoutMixins from '@/mixins/timeout.js'

  export default {
    name: 'Navbar',
    props: {
      values: {
        type: Object
      }
    },
    mixins: [timeoutMixins],
    data() {
      return {
        activateSection: `home`,
        activeMenu: false,
        eventsTrigger: [''],
        eventsFired: []
      }
    },
    mounted() {
      window.addEventListener('scroll', () => {
        this.sectionScroll()
        this.activeMenu = false
      })
    },
    computed: {
      percentScreen() {
        return d3.scaleThreshold().domain(
          [1, document.body.clientHeight]
        ).range(
          [
            25, 50, 75, 100
          ])
      }
    },
    methods: {
      countLink(link, name) {

        window.dataLayer.push({
          event: "navigation_click",
          event_action: link
        })

        if(document.getElementById(name)) {
          const top = document.getElementById(name).offsetTop
          if(document.getElementById(`${name}-nav`)) {
            const navlinks = document.querySelectorAll('.link-nav-univ')
            
            Array.from(navlinks).forEach((i)=> {
              i.classList.remove('active')
            })

            const link = document.getElementById(`${name}-nav`)
            link.classList.add('active')
          }
              
          document.getElementById(name).classList.add('active')
          document.getElementById(`${name}-nav`).classList.add('active')
          window.scrollTo(0, top)
        }
      },
      showMenu() {
        this.activeMenu = !this.activeMenu
      },
      sectionScroll() {
        const triggers = document.querySelectorAll('.sections-univ')
       // const navlinks = document.querySelectorAll('.link-nav-univ')
        this.activeMenu = false



        Array.from(triggers).forEach((i)=> {
          if(i.dataset.section != this.activateSection) {
            const scrollY = window.scrollY + (window.innerHeight * .7)
            const top = i.offsetTop


            if(window.scrollY > window.innerHeight/5) {
              if(this.$refs['navbar-layout'])
                this.$refs['navbar-layout'].classList.add("small")
            } else {
              if(this.$refs['navbar-layout'])
                this.$refs['navbar-layout'].classList.remove("small")
            }

            if( top <= scrollY && (top+ i.offsetHeight) >= scrollY) {

              // if(document.getElementById(`${i.dataset.section}-nav`)) {
              //   Array.from(navlinks).forEach((i)=> {
              //     console.log(i)
              //     i.classList.remove('active');
              //   })
              //   const link = document.getElementById(`${i.dataset.section}-nav`)
              //   link.classList.add('active')
              // }
              
              //active section based on scrolldown  
              this.activateSection = i.dataset.section
              i.classList.add('active')

            }
          }
        })
      }
    }
  }   
</script>